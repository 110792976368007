import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';

const url = process.env.GATSBY_HASURA_BASE_URL;

const client = new ApolloClient( {
  cache: new InMemoryCache(),
  link: new HttpLink( {
    uri: url,
    fetch
  } )
} );

export default client;
