/*
This is an example snippet - you should consider tailoring it
to your service.
*/

async function fetchGraphQL( operationsDoc, operationName, variables ) {
  const result = await fetch(
    process.env.GATSBY_HASURA_BASE_URL,
    {
      method: 'POST',
      body: JSON.stringify( {
        query: operationsDoc,
        variables: variables,
        operationName: operationName
      } )
    }
  );

  return await result.json();
}

const operationsDoc = `
  query MyQuery($_eq: String = "") {
    agentportalagents(where: {auth0_id: {_eq: $_eq}}) {
      auth0_id
      company
      custom_url
      description
      email
      facebook
      first_name
      full_name
      lead_source
      instagram
      last_name
      linkedin
      phone_no
      profile_url
      twitter
    }
  }
`;

const prodOperationsDoc = `
  query MyQuery($_eq: String = "") {
    agentportalagents: prod_agentportalagents(where: {auth0_id: {_eq: $_eq}}) {
      auth0_id
      company
      custom_url
      description
      email
      facebook
      first_name
      lead_source
      full_name
      instagram
      last_name
      linkedin
      phone_no
      profile_url
      twitter
    }
  }
`;

function fetchMyQuery( _eq ) {
  return fetchGraphQL(
    process.env.GATSBY_ENV == 'PROD' ? prodOperationsDoc : operationsDoc,
    'MyQuery',
    { _eq: _eq }
  );
}

async function startFetchMyQuery( _eq ) {
  const { errors, data } = await fetchMyQuery( _eq );

  if ( errors ) {
    // handle those errors like a pro
    console.error( errors );
  }

  // do something great with this precious data
  return { errors, data };
}

export { startFetchMyQuery };
