const React = require( 'react' );
const { Auth0Provider } = require( '@auth0/auth0-react' );
const { UserProvider } = require( './src/context/context' );
const { navigate } = require( 'gatsby' );
const { ApolloProvider } = require(  '@apollo/client' );

const client = require( './src/gatsby-plugin-apollo/client' );

const onRedirectCallback = ( appState ) => {
  // Use Gatsby's navigate method to replace the url
  navigate( appState?.returnTo || '/', { replace: true } );
};

exports.wrapRootElement = ( { element } ) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_URL}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      redirectUri={window.location.origin}>
      <ApolloProvider client={client}>

        <UserProvider>

          {element}

        </UserProvider>
      </ApolloProvider>
    </Auth0Provider>
  );
};
