
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

import { startFetchMyQuery } from './getData';


function ContextHelper( { updateAgent } ) {
  const {  isLoading, getIdTokenClaims } = useAuth0();

  function okay(){
    getIdTokenClaims().then( claims => {
      const { sub } = claims;

      startFetchMyQuery( sub ).then( ( { data, errors } ) => {
        updateAgent( { ...data?.agentportalagents[0], id: sub } );
      } );
    } ).catch( err => {
      console.debug( 'Something went wrong', err );
    } );
  }

  useEffect( okay, [isLoading] );
  return(
    <>
    </>
  );
}

export default ContextHelper;
