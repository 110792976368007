import React,{ Component , createContext } from 'react';

import ContextHelper from './contextHelper';

const user = {};

const UserContext = createContext( user || {} );

class UserProvider extends Component {

  state = {
    agent: {},
    transactions: []
  };

  updateAgent = ( agent ) => {
    this.setState(
      { agent: agent }
    );
  };

  getAgent = async() => {
    const resp = await fetch( process.env.GATSBY_API_BASE_URL + '/transaction' );
    const data = await resp.json();
    this.setState( { transactions: data } );
  };

  componentDidMount() {
    // this.getAgent();
  }

  render() {
    const { children } = this.props;
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          updateAgent: this.updateAgent
        }}
      >
        <ContextHelper updateAgent={this.updateAgent}/>

        {children}
      </UserContext.Provider>
    );
  }
}

export default  UserContext ;

export { UserProvider };
