import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme( {
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  shape: {
    pillRadius: '27.5'
  },
  MuiButton: {
    variants: [
      {
        props: {
          variant: 'round',
          color: 'orange'
        },
        style: {
          textTransform: 'none',
          borderRadius: '27.5px'
        }
      },
      {
        props: {
          variant: 'action',
          color: 'white'
        },
        style: {
          background: '#F46904',
          borderRadius: '25px'
        }
      }
    ]
  },
  palette: {
    primary: {
      main: '#556cd6'
    },
    white: {
      main: '#fff'
    },
    secondary: {
      main: '#19857b'
    },
    orange:{
      main: '#ff7007'
    },
    error: {
      main: red.A400
    }
  }
} );

export default theme;
